.div {
  margin: auto auto 1rem auto;
  text-align: center;
  width: 100%;
  grid-row: 1/2;
  grid-column: 1/4;
}

.title {
  height: 20%;
  margin: 2rem auto 1.5rem auto;
  font: Noto Sans;
  color: #383636;
  font-weight: 600;
}

.subtitle {
  font: Noto Sans;
  color: #383636;
  font-weight: 500;
}

.div2 {
  margin: auto;
  text-align: center;
  width: 100%;
  grid-row: 1/2;
  grid-column: 1/4;
}

.title2 {
  height: 20%;
  margin: 0 auto 1.5rem auto;
  font: Noto Sans;
  color: #383636;
  font-weight: 600;
}
