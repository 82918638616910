.main {
  position: relative;
  height: 40rem;
  width: 40%;
  margin: 2rem auto;
  box-shadow: 0px 5px 14px lightgray;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 3fr 2fr;
}

.fileNameBorder {
  position: relative;
  display: grid;
  grid: 100% / 80% 20%;
  display: grid;
  grid-column: 1/4;
  grid-row: 3/4;
  margin: 1rem auto 3rem auto;
  border: 1px black solid;
  border-radius: 5px;
  height: 2.2rem;
  width: 86%;
}

.fileName {
  position: relative;
  grid-column: 1/2;
  justify-self: left;
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.025rem;
  color: #707070;
  margin: 0.5rem auto auto 1rem;
}

.copy {
  position: relative;
  grid-column: 2/3;
  justify-self: right;
  height: 100%;
  width: 6rem;
  font: Noto Sans;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  border: none;
  border-radius: 4px;
  letter-spacing: 0.02rem;
  background-color: #2f80ed;
  cursor: pointer;
}

.chooseFileButton {
  grid-column: 2/3;
  grid-row: 3/4;
  margin: 4rem 9rem auto 8%;
  height: 2rem;
  width: 6rem;
  text-align: center;
  box-shadow: 2px 2px 5px lightgrey;
  border-radius: 8px;
  background-color: #2f80ed;
}
.buttonText {
  margin: 7px auto;
  font: Noto Sans;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.035rem;
}

.clearButton {
  grid-column: 2/3;
  grid-row: 3/4;
  margin: 4rem auto auto 50%;
  height: 2rem;
  width: 6rem;
  text-align: center;
  box-shadow: 2px 2px 5px lightgrey;
  border-radius: 8px;
  border: none;
  background-color: #2f80ed;
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.03rem;
}
